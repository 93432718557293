.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Workexp {
  padding: 40px;
  text-align: left;
}

.yosemite_valley {
  background-color: black;
  max-width: 100%;
}

.yosemite_img {
  max-width: 100%;
}

.timeline{
  padding-left:0px;
}

.footer {
  background-color: black;
  padding: 20px;
  text-align: left;
  color: white;
}

.Footer {
  background-color: #007bff;
  padding: 20px;
  text-align: left;
  color: white;
}


.Footer-social {
  text-align:right;
}

.BJJ {
  background: lightblue;
  border-radius: 10px;
  padding:20px;
  max-width: 500px;
}

.Hobbies {
  padding:40px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.Hiking {
  background: lightgreen;
  border-radius: 10px;
  padding:20px;
  max-width: 500px;
}

.Kili {
  max-width: 300px;
}

.cards {
  align-items: center;
  justify-content: center;
  text-align: center;}


.About {
  padding:40px;
  text-align: left;
}

.Abouttext {
  font-size:30px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
